export const chartColors = [
  '#336699',
  '#99CCFF',
  '#999933',
  '#666699',
  '#CC9933',
  '#006666',
  '#3399FF',
  '#993300',
  '#CCCC99',
  '#666666',
  '#FFCC66',
  '#6699CC',
  '#663366',
  '#9999CC',
  '#CCCCCC',
  '#669999',
  '#CCCC66',
  '#CC6600',
  '#9999FF',
  '#0066CC',
  '#99CCCC',
  '#999999',
  '#FFCC00',
  '#009999',
  '#99CC33',
  '#FF9900',
  '#999966',
  '#66CCCC',
  '#339966',
  '#CCCC33',
  '#003f5c',
  '#665191',
  '#a05195',
  '#d45087',
  '#2f4b7c',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#EF6F6C',
  '#465775',
  '#56E39F',
  '#59C9A5',
  '#5B6C5D',
  '#0A2342',
  '#2CA58D',
  '#84BC9C',
  '#CBA328',
  '#F46197',
  '#DBCFB0',
  '#545775',
];

export const backgroundColor = [
  'rgba(255, 99, 132, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(255, 205, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(201, 203, 207, 1)',
];

export const gradientsColors = {
  default: ['rgba(102, 255, 0, 0)',
    'rgba(102, 255, 0, 1)',
    'rgba(147, 255, 0, 1)',
    'rgba(193, 255, 0, 1)',
    'rgba(238, 255, 0, 1)',
    'rgba(244, 227, 0, 1)',
    'rgba(249, 198, 0, 1)',
    'rgba(255, 170, 0, 1)',
    'rgba(255, 113, 0, 1)',
    'rgba(255, 57, 0, 1)',
    'rgba(255, 0, 0, 1)',
  ],
  viridisLighter: [
    'rgba(53, 44, 200, 0)',
    'rgba(53, 44, 167, 1)',
    'rgba(41, 82, 157, 1)',
    'rgba(29, 109, 147, 1)',
    'rgba(15, 152, 133, 1)',
    'rgba(3, 177, 126, 1)',
    'rgba(42, 192, 103, 1)',
    'rgba(87, 207, 80, 1)',
    'rgba(135, 217, 52, 1)',
    'rgba(175, 228, 36, 1)',
    'rgba(246, 246, 0, 1)',
  ],
  cyanYellow: [
    'rgba(0, 200, 225, 0)',
    'rgba(0, 164, 221, 1)',
    'rgba(28, 185, 177, 1)',
    'rgba(54, 205, 136, 1)',
    'rgba(74, 221, 104, 1)',
    'rgba(122, 219, 76, 1)',
    'rgba(173, 217, 47, 1)',
    'rgba(211, 216, 25, 1)',
    'rgba(232, 215, 13, 1)',
    'rgba(255, 214, 0, 1)',
    'rgba(255, 244, 0, 1)',
  ],
  blueRed: [
    'rgba(0, 255, 255, 0)',
    'rgba(0, 255, 255, 1)',
    'rgba(0, 191, 255, 1)',
    'rgba(0, 127, 255, 1)',
    'rgba(0, 63, 255, 1)',
    'rgba(0, 0, 255, 1)',
    'rgba(0, 0, 223, 1)',
    'rgba(0, 0, 191, 1)',
    'rgba(0, 0, 159, 1)',
    'rgba(0, 0, 127, 1)',
    'rgba(63, 0, 91, 1)',
    'rgba(127, 0, 63, 1)',
    'rgba(191, 0, 31, 1)',
    'rgba(255, 0, 0, 1)',
  ],
  plasma: [
    'rgba(71, 2, 159, 0)',
    'rgba(21, 6, 138, 1)',
    'rgba(101, 0, 167, 1)',
    'rgba(137, 8, 165, 1)',
    'rgba(162, 28, 154, 1)',
    'rgba(127, 0, 63, 1)',
    'rgba(199, 66, 123, 1)',
    'rgba(220, 94, 102, 1)',
    'rgba(232, 112, 89, 1)',
    'rgba(242, 132, 75, 1)',
    'rgba(253, 175, 49, 1)',
    'rgba(253, 175, 49, 1)',
    'rgba(252, 206, 37, 1)',
    'rgba(245, 231, 38, 1)',
  ],
  cividis: [
    'rgba(0, 37, 84, 0)',
    'rgba(0, 37, 84, 1)',
    'rgba(33, 59, 110, 1)',
    'rgba(54, 70, 108, 1)',
    'rgba(78, 86, 108, 1)',
    'rgba(110, 112, 115, 1)',
    'rgba(131, 128, 120, 1)',
    'rgba(160, 151, 117, 1)',
    'rgba(194, 178, 105, 1)',
    'rgba(203, 186, 100, 1)',
    'rgba(210, 191, 96, 1)',
    'rgba(253, 230, 54, 1)',
  ],
  redToGreen: [
    'rgba(231, 75, 49, 0)',
    'rgba(231, 75, 49, 1)',
    'rgba(229, 92, 24, 1)',
    'rgba(224, 109, 0, 1)',
    'rgba(214, 126, 0, 1)',
    'rgba(201, 143, 0, 1)',
    'rgba(184, 159, 0, 1)',
    'rgba(163, 174, 0, 1)',
    'rgba(136, 187, 0, 1)',
    'rgba(100, 200, 0, 1)',
    'rgba(25, 212, 25, 1)',
  ],
};
