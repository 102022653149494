import { styled, Container } from '@mui/material';
import { theme } from '../../pages/themes/default';

const colors = {
  darkgrey: theme.palette.text.main,
  grey: theme.palette.text.light,
};

export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  align-items: center;
  text-align: center;

  margin-top: 150px;

  img {
    width: 300px;
  }

  button {
    height: 41px;
    margin-top: 40px;
  }

  h3 {
    font-size: 30px;
    text-transform: uppercase;
    color: ${colors.darkgrey};
  }
`;

export const Title = styled('h1')`
  display: flex;

  font-size: 60px;
  text-transform: uppercase;
  color: ${colors.darkgrey};
`;
