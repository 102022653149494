import React from 'react';
import { useNavigate } from 'react-router';
import { Wrapper, Title } from './styles';
import { Button } from '../Touchables/Button';

export default function GlobalErrorScreen() {
  const navigate = useNavigate();
  const handleRedirectPage = () => navigate(0);

  return (
    <Wrapper>
      <Title>Algo deu errado...</Title>
      <div>
        <img src="/assets/dead_leaf.svg" alt="Folhas mortas" />
        <h3>Vamos tentar de novo?</h3>

      </div>
      <Button
        variant="contained"
        onClick={handleRedirectPage}
      >
        Voltar
      </Button>
    </Wrapper>
  );
}
